import React from 'react';
import Features from '../../components/Features';
import Header from '../../components/Header';
import Welcome from '../../components/Welcome';
import Footer from '../../components/Footer';

const HomeScreen = () => (
  <div>
    <Header />
    <Welcome />
    <Features />
    <Footer />
  </div>
);

export default HomeScreen;
