import React from 'react';
import googleBadge from '../../assets/imgs/es_badge_google_play.png';

const Welcome = () => (
  <div className='welcome-area' id='welcome'>
    <div className='header-text'>
      <div className='container'>
        <div className='row'>
          <div
            className='left-text col-lg-5 col-md-12 col-sm-12 col-xs-12'
            data-scroll-reveal='enter left move 30px over 0.6s after 0.4s'
          >
            <h1>CombuRD</h1>
            <p>
              CombuRD es una aplicación que te ofrece una experiencia
              maravillosa de ver los precio de los combustibles de la República
              Dominicana.
            </p>
            <a
              href='https://play.google.com/store/apps/details?id=com.uma.combustiblesrd&hl=es'
              target='_blank'
              rel='noreferrer'
            >
              <img
                className='store-button'
                src={googleBadge}
                alt='Google badge button'
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Welcome;
