import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header/Header';
import PrivacyPolicy from '../../components/PrivacyPolicy';

const PrivacyPolicyScreen = () => (
  <div>
    <Header showMenu={false} />
    <PrivacyPolicy />
    <Footer />
  </div>
);

export default PrivacyPolicyScreen;
