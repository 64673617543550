import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFieldForm } from '../../hooks/form-hook';

const Footer = () => {
  let [formData, setFormData, isFieldsValid, cleanForm] = 
    useFieldForm({'email': '', 'message': '', 'fullName': ''}) ;
  let [isLoading, setIsLoading] = useState(false);
  let [isMessageSent, setIsMessageSent] = useState(false);

  return (
    <footer id='contact-us'>
      <div className='container'>
        <div className='footer-content'>
          <div className='row'>
            <div className='col-lg-6 col-md-12 col-sm-12'>
              <div className='contact-form'>
                <form id='contact'>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <fieldset>
                        <input
                          type='text'
                          placeholder='Nombre Completo'
                          required
                          name='fullName'
                          value={formData['fullName']}
                          style={{ backgroundColor: 'rgba(250,250,250,0.3)' }}
                          onChange={setFormData}
                        />
                      </fieldset>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <fieldset>
                        <input
                          type='text'
                          placeholder='E-Mail'
                          required=''
                          name='email'
                          value={formData['email']}
                          style={{ backgroundColor: 'rgba(250,250,250,0.3)' }}
                          onChange={setFormData}
                        />
                      </fieldset>
                    </div>
                    <div className='col-lg-12'>
                      <fieldset>
                        <textarea
                          rows='6'
                          placeholder='Tu Mensaje'
                          required
                          name='message'
                          value={formData['message']}
                          style={{ backgroundColor: 'rgba(250,250,250,0.3)' }}
                          onChange={setFormData}
                        ></textarea>
                      </fieldset>
                    </div>
                    <div className='col-lg-12'>
                      <fieldset>
                        <button
                          type='submit'
                          id='form-submit'
                          className='main-button'
                          disabled={isLoading}
                          onClick={(e) => {
                            e.preventDefault();

                            if (isFieldsValid) {
                              setIsLoading(true);
                              setTimeout(() => {
                                cleanForm({'email': '', 'message': '', 'fullName': ''});
                                setIsMessageSent(true);
                                setIsLoading(false);
                              }, 1000);
                            }
                          }}
                        >
                          Enviar Mensaje
                        </button>
                        
                      </fieldset>
                      <br/>
                    </div>
                    {isLoading && <progress style={{width: '100%'}}></progress>}
                    { !isLoading && isMessageSent && <div className='col-lg-12'>
                      <label>Mensaje enviado con exito.</label>
                    </div> }
                  </div>
                </form>
              </div>
            </div>
            <div className='right-content col-lg-6 col-md-12 col-sm-12'>
              <h2>
                Mas Sobre <em>CombuRD</em>
              </h2>
              <p>
                CombuRD te ofrece una manera comoda y rápida de ver los precios
                de los combustibles en República Dominicana. Se el primero en
                enterarte cada viernes de los nuevos precios de los
                combustibles.
                <br />
                <br />
                Localiza todas todas las estaciones de combustible mas cercanas
                en la zona en donde te encuentras.
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='sub-footer'>
              <p>
                <Link to='/politica-de-privacidad'>
                  Politica de Privacidad{' '}
                </Link>
                | Copyright &copy; {new Date().toISOString().slice(0, 4)}{' '}
                CombuRD
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
