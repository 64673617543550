import React from 'react';

// eslint-disable-next-line react/prop-types
const Header = ({ showMenu = true }) => (
  <header className='header-area header-sticky'>
    <div className='container'>
      <div className='row'>
        <div className='col-12'>
          <nav className='main-nav'>
            <a href='/' className='logo'>
              CombuRD
            </a>
            <ul
              className='nav'
              style={{ visibility: showMenu ? 'visible' : 'hidden' }}
            >
              <li className='scroll-to-section'>
                <a href='#welcome' className='menu-item'>
                  Inicio
                </a>
              </li>
              <li className='scroll-to-section'>
                <a href='#about' className='menu-item'>
                  Caracteristicas
                </a>
              </li>
              <li className='scroll-to-section'>
                <a href='#contact-us' className='menu-item'>
                  Contactanos
                </a>
              </li>
            </ul>
            <div className='menu-trigger'>
              <span>Menu</span>
            </div>
          </nav>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
