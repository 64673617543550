import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';

class App extends Component {
  render() {
    return (
      <div>
        <div id='preloader'>
          <div className='jumper'>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <Router>
          <Route exact path='/' component={HomeScreen} />
          <Route
            exact
            path='/politica-de-privacidad'
            component={PrivacyPolicyScreen}
          />
        </Router>
      </div>
    );
  }
}

export default App;
