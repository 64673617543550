import {useState} from 'react';

function handleFormFieldData(formData, setFormData) {
  return (e) => {
    let name = e.target.name;
    let value = e.target.value;
  
    setFormData({...formData, [name]: value});
  };
}

function cleanFormField(setFormData) {
  return (values = {}) => {
    setFormData({...values});
  };
}

function formValidation(formData) {
  return Object.keys(formData).every(name => {
    if (name === 'email') {
      return formData[name].toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    }
    return formData[name];
  });
}

const useFieldForm = (fields) => {
  let [formData, setFormData] = useState(fields);
  let isFieldsValid = formValidation(formData);

  return [
    formData, 
    handleFormFieldData(formData, setFormData), 
    isFieldsValid, 
    cleanFormField(setFormData)
  ];
};

export { useFieldForm };