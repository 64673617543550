import React from 'react';

const Features = () => (
  <div>
    <section className='section' id='about'>
      <div className='container'>
        <div className='row'>
          <div
            className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
            data-scroll-reveal='enter left move 30px over 0.6s after 0.4s'
          >
            <div className='features-item'>
              <div className='features-icon'>
                <h2>01</h2>
                <img src='assets/images/features-icon-1.png' alt='' />
                <h4>Gráficos</h4>
                <p>Gráficos de cada tipos de combustible.</p>
              </div>
            </div>
          </div>
          <div
            className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
            data-scroll-reveal='enter bottom move 30px over 0.6s after 0.4s'
          >
            <div className='features-item'>
              <div className='features-icon'>
                <h2>02</h2>
                <img src='assets/images/features-icon-2.png' alt='' />
                <h4>Estaciones</h4>
                <p>Lista de estaciones de combustibles más cercanas.</p>
              </div>
            </div>
          </div>
          <div
            className='col-lg-4 col-md-6 col-sm-12 col-xs-12'
            data-scroll-reveal='enter right move 30px over 0.6s after 0.4s'
          >
            <div className='features-item'>
              <div className='features-icon'>
                <h2>03</h2>
                <img src='assets/images/features-icon-3.png' alt='' />
                <h4>Notificación</h4>
                <p>Notificaciones semanales de los nuevo precios.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className='left-image-decor'></div>
    <section className='section' id='promotion'>
      <div className='container'>
        <div className='row'>
          <div
            className='left-image col-lg-5 col-md-12 col-sm-12 mobile-bottom-fix-big'
            data-scroll-reveal='enter left move 30px over 0.6s after 0.4s'
          >
            <img
              src='assets/images/left-image.png'
              className='rounded img-fluid d-block mx-auto'
              alt='App'
            />
          </div>
          <div className='right-text offset-lg-1 col-lg-6 col-md-12 col-sm-12 mobile-bottom-fix'>
            <ul>
              <li data-scroll-reveal='enter right move 30px over 0.6s after 0.4s'>
                <img src='assets/images/about-icon-01.png' alt='' />
                <div className='text'>
                  <h4>Gestor de Consumos</h4>
                  <p>
                    Gestione sus consumos de combustibles para llevar un mejor
                    control de sus gastos.
                  </p>
                </div>
              </li>
              <li data-scroll-reveal='enter right move 30px over 0.6s after 0.5s'>
                <img src='assets/images/about-icon-02.png' alt='' />
                <div className='text'>
                  <h4>Historial de Precios</h4>
                  <p>
                    Revise el historial de precios que ha tenido cada tipo de
                    los combustibles.
                  </p>
                </div>
              </li>
              <li data-scroll-reveal='enter right move 30px over 0.6s after 0.5s'>
                <img src='assets/images/about-icon-02.png' alt='' />
                <div className='text'>
                  <h4>Copia de Seguridad</h4>
                  <p>
                    Realice una copia de seguridad para mantener su información
                    en la nubes.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Features;
